<template>
  <v-container>
    <div class="back-plot">
      <!-- BOC:[error] -->
      <ApiErrorDialog v-if="api.isError" :api="api" />
      <!-- EOC -->
      <!-- BOC -->
      <v-card v-if="data.Institution" class="transparent" elevation="0">
        <v-card-title class="text-h5 grey lighten-5 rounded-lg py-2">
          <v-row>
            <v-col cols="9" class="d-flex rounded align-center">
              <div class="pr-3">
                <v-avatar size="48">
                  <v-img
                    v-if="data.Institution.logoUrl"
                    :src="data.Institution.logoUrl"
                    class="ml-auto"
                  ></v-img>
                  <v-img v-else src="/images/Icon.png" class="ml-auto"></v-img>
                </v-avatar>
              </div>
              {{ data.Institution.name }}
            </v-col>
            <v-col cols="3" class="d-flex align-center justify-end">
              <CloseBtn size="40" :callback="exit" />
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
      <v-card v-else class="transparent" elevation="0">
        <v-card-title class="text-h5 grey lighten-5 rounded-lg py-2">
          <v-row>
            <v-col cols="9" class="d-flex rounded align-center">
              <div class="pr-3">
                <v-avatar size="48">
                  <v-img src="/images/Icon.png" class="ml-auto"></v-img>
                </v-avatar>
              </div>
              Personal
            </v-col>
            <v-col cols="3" class="d-flex align-center justify-end">
              <CloseBtn size="40" :callback="exit" />
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
      <!-- EOC -->
      <v-card
        :class="
          settings.graphics && settings.graphics.mode == 'low'
            ? 'low_plot'
            : 'plot'
        "
        :loading="api.isLoading"
      >
        <v-card-title class="pt-2">{{
          $t("view.PageGuestSchool.title")
        }}</v-card-title>
        <v-card-text>
          <v-text-field
            v-if="data.Classroom && data.Classroom.length > 5"
            hide-details
            prepend-inner-icon="mdi-magnify"
            class="mt-5 mx-3"
            :label="$t('view.PageGuestSchool.search')"
            :placeholder="$t('view.PageGuestSchool.search')"
            solo
            v-model="searchKey"
            @keyup="search"
          ></v-text-field>

          <v-card
            v-if="suggest && !searchKey"
            :key="i"
            elevation="0"
            class="suggested green lighten-5 d-flex flex-row justify-space-between align-center pa-4 my-3"
            color="green lighten-4"
          >
            <div class="font-weight-medium text-h6">
              <v-chip small color="green lighten-3">{{
                $t("string.lastLogin")
              }}</v-chip>
              <br />
              {{ suggest.name }}
            </div>

            <v-btn
              large
              color="green lighten-1"
              rounded
              class="text-h6"
              style="text-transform: capitalize"
              @click="selectClassroom(suggest.id, suggest)"
            >
              {{ $t("action.select") }}
            </v-btn>
          </v-card>
          <v-card
            v-for="(item, i) in searchData"
            :key="i"
            elevation="0"
            class="green lighten-5 d-flex flex-row justify-space-between align-center pa-4 my-3"
            :color="selectedClassroom == item.id ? ' green lighten-3' : null"
          >
            <div class="font-weight-medium text-h6">{{ item.name }}</div>

            <v-btn
              large
              color="green lighten-1"
              rounded
              class="text-h6"
              style="text-transform: capitalize"
              @click="selectClassroom(item.id, item)"
            >
              {{ $t("action.select") }}
            </v-btn>
          </v-card>
          <v-card
            v-if="searchData.length == 0"
            elevation="0"
            class="green lighten-5 d-flex flex-row justify-space-between align-center pa-4 my-3"
          >
            <div class="font-weight-medium text-h6">
              {{ $t("string.noItem") }}
            </div>
          </v-card>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
let click_sfx = new Audio(require("@/assets/sounds/sfx_click_5.wav"));
export default {
  components: {
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
    ApiErrorDialog: () =>
      import(
        /* webpackChunkName: "component-api-error-dialog" */ "@/components/ApiErrorDialog.vue"
      ),
  },
  computed: mapState({
    auth: (state) => state.auth.data,
    settings: (state) => state.settings.data,
  }),
  props: [
    //
  ],
  data: () => ({
    suggest: null,
    searchKey: "",
    selectedClassroom: null,
    classroom: null,
    code: null,
    institution: {},
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    data: {},
    searchData: [],
  }),
  created() {
    this.api.method = "get";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.data = resp;
      this.searchData = this.data.Classroom;

      if (this.auth && this.auth.Classroom && this.data.Classroom.length > 5) {
        this.suggest = this.searchData.find(
          (element, index) => element.id == this.auth.Classroom.id && index > 5
        );
        if (this.suggest) {
          this.searchData = this.searchData.filter(
            (item) => item !== this.suggest
          );
          this.searchData.unshift(this.suggest);
        }
      }
      if (this.data.Institution) {
        if (this.auth) {
          this.institution = this.$_.cloneDeep(this.auth);
        }
        this.institution.Account = this.$_.clone(this.data.Account);
        this.institution.Institution = this.$_.clone(this.data.Institution);
        this.$store.commit("updateAuth", this.institution);
      } else {
        if (this.auth) {
          this.institution = this.$_.cloneDeep(this.auth);
        }
        this.institution.Account = this.$_.clone(this.data.Account);
        this.institution.Institution = false;
        this.$store.commit("updateAuth", this.institution);
      }
    };
  },
  mounted() {
    if (this.auth.Group) {
      this.code = this.auth.Group.code;
      this.fetch();
    } else {
      this.$router.push({ name: "PageAccessMain" });
    }
  },
  methods: {
    search() {
      if (this.searchKey.trim().length == 0) {
        this.searchData = this.data.Classroom;
      } else {
        let key = this.searchKey.trim().toLowerCase();
        this.searchData = this.data.Classroom.filter(function (element) {
          return element.name.toLowerCase().includes(key);
        });
      }
    },
    fetch() {
      this.api.url =
        this.$api.servers.sso +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/classroom/" +
        this.code;
      this.$api.fetch(this.api);
    },
    selectClassroom(id, classroom) {
      this.selectedClassroom = id;
      this.classroom = classroom;
      click_sfx.play();
      click_sfx.volume = this.settings.audio.sfx * this.settings.audio.master;
      var authData = this.$_.cloneDeep(this.auth);
      authData.Classroom = classroom;
      this.$store.commit("updateAuth", authData);
      this.$router.push({
        name: "PageAccessClassroom",
      });
    },
    exit() {
      this.$router.push({
        name: "PageAccessMain",
      });
    },
  },
};
</script>

<style scoped>
.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}

.image1 {
  position: absolute;
  z-index: 2;
}
.image2 {
  position: absolute;
  z-index: 3;
}

.image3 {
  position: absolute;
  z-index: 1;
}

.title {
  pointer-events: none;
  font-size: 1em !important;
}
</style>